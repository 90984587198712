<template>
  <div class="bookmaster-wraper">
    <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <div class="row mb-3">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("student_order.serve_date") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-date-range-picker
                    name="sell_date"
                    v-model="sell_date"
                    timePicker24Hour="false"
                    rules="required"
                    format="YYYY-MM-DD"
                  />
                </div>
              </div>
              <div v-if="sell_date" class="row justify-content-end">
                  <button type="button" class="btn btn-primary mr-2" @click="exportOrderMenus">{{ $t('student_order.btn_export_order_menus') }}</button>
                  <button type="button" class="btn btn-primary"  @click="exportOrderLabels">{{ $t('student_order.btn_export_order_labels') }}</button>
                </div>
            </div>
          </div>
        </div>
      </form>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "OrderExportPage",

  data() {
    return {
      sell_date: null
    }
  },

  methods: {
    async exportOrderMenus() {
      let res = null;
      let msg = "";
      let params = {sell_date: this.sell_date}
      let exportDate = moment(this.sell_date, 'YYYY-MM-DD').format('MMDDYYYY');
      res = await this.$request.get(this.ENDPOINT.KYY_EXPORT_ORDER_MENUS, params, {responseType:'blob'});
      msg = this.$t("common_vn.export_ok")
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        this.resolveAndDownloadBlob(res.data, `bangtonghop_${exportDate}.xlsx`)
      }
    },

    async exportOrderLabels() {
      let res = null;
      let msg = "";
      let params = {sell_date: this.sell_date}
      let exportDate = moment(this.sell_date, 'YYYY-MM-DD').format('MM-DD-YYYY');
      res = await this.$request.get(this.ENDPOINT.KYY_EXPORT_ORDER_LABELS, params, {responseType:'blob'});
      msg = this.$t("common_vn.export_ok")
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        this.resolveAndDownloadBlob(res.data, `innaphop_${exportDate}.xlsx`)
      }
    },

    resolveAndDownloadBlob (byte, file_name) {
      file_name = decodeURI(file_name)
      const url = window.URL.createObjectURL(new Blob([byte]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file_name)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    },
  }
}
</script>
